<template>
  <div>
    <detailHeader heading="Food Items" v-if="restaurant_detail.theme.id != 4" />
    <foodItems v-on:setSubsectonId="setSubSecId" />
    <mainFooter />
    <bottomNav :route_back="route_back" />
  </div>
</template>

<script>
import detailHeader from "@/components/layouts/DetailHeader";
import foodItems from "@/components/menu/FoodItems";
import bottomNav from "@/components/layouts/AppNav";
import mainFooter from "@/components/layouts/Footer";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    detailHeader,
    foodItems,
    bottomNav,
    mainFooter,
  },
  computed: mapGetters(["restaurant_detail"]),

  data() {
    return {
      category_id: 0,
      subSection: [],
      route_back: {
        name: "",
        params: {},
        display_name: "",
      },
    };
  },
  methods: {
    setSubSecId(id) {
      this.route_back.name = "MainSubMenuComponent";
      this.route_back.params = {
        category_id: id,
      };
      this.route_back.display_name =
        this.restaurant_detail.localization.misc_back;
    },
  },
  mounted() {},
};
</script>
