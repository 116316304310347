<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered is-mobile" v-if="restaurant_detail.checkout_message">
          <div class="column" :class="{mql: mql, 'is-half' : !mql}">
            <article class="message is-warning">
              <div class="message-header">
                <p>Note</p>
              </div>
              <div class="message-body">{{ restaurant_detail.checkout_message }}</div>
            </article>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-5-tablet is-5-desktop is-5-widescreen">
            <form action class="box">
              <div class="field" v-if="table_no == null">
                <label for class="label">
                  {{ restaurant_detail.localization.checkout_table_no }}
                  <span class="text-danger" v-if="restaurant_detail.subdomain_required_table_no">*</span>
                </label>
                <div class="control">
                  <input

                      type="number"
                      placeholder="Table #"
                      v-model="order.table_no"
                      class="input"
                      required
                      v-bind:readonly="table_no"
                  />
                  <span class="text-danger" v-for="error in  validation_messages.table_no">
                    {{ error }}
                    <br/>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for class="label">
                  {{ restaurant_detail.localization.checkout_customer_name }}
                  <span class="text-danger" v-if="restaurant_detail.subdomain_required_customer_name">*</span>

                </label>
                <div class="control">
                  <input type="text" class="input" v-model="user.name" required/>
                  <span class="text-danger" v-for="error in  validation_messages.name">
                    {{ error }}
                    <br/>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for class="label">{{ restaurant_detail.localization.checkout_email }}
                  <span class="text-danger" v-if="restaurant_detail.subdomain_required_customer_email">*</span>


                </label>
                <div class="control">
                  <input type="email" class="input" v-model="user.email" required/>
                  <span class="text-danger" v-for="error in  validation_messages.email">
                    {{ error }}
                    <br/>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for class="label">{{ restaurant_detail.localization.checkout_phone }}

                  <span class="text-danger" v-if="restaurant_detail.subdomain_required_customer_phone">*</span>

                </label>
                <div class="control">
                  <input type="text" class="input" v-model="user.phone" required/>
                  <span class="text-danger" v-for="error in  validation_messages.phone">
                    {{ error }}
                    <br/>
                  </span>
                </div>
              </div>

              <div class="field">
                <label
                    for
                    class="label"
                >{{ restaurant_detail.localization.checkout_special_instructions }}
                  <span class="text-danger" v-if="restaurant_detail.subdomain_required_special_instruction">*</span>

                </label>
                <div class="control">

                  <textarea cols="30" rows="10" v-model="order.note" class="input"></textarea>
                  <span class="text-danger" v-for="error in  validation_messages.note">
                    {{ error }}
                    <br/>
                  </span>
                </div>
              </div>

              <div class="field">
                <label for class="label">{{ restaurant_detail.localization.checkout_payment_method }}</label>
                <div class="control">
                  <select name="payment_method" v-model="order.payment_method" class="input">
                    <option v-if="restaurant_detail.payment_cash" value="CASH">
                      {{ restaurant_detail.localization.checkout_cash }}
                    </option>
                    <option v-if="restaurant_detail.payment_card" value="CARD">
                      {{ restaurant_detail.localization.checkout_card }}
                    </option>
                    <option value="ONLINE" v-if="restaurant_detail.paypal_active">
                      {{ restaurant_detail.localization.checkout_paypal }}
                    </option>
                  </select>

                </div>
              </div>

              <div class="field">
                <div v-if="order.payment_method == 'ONLINE' && this.valid_form">
                  <PayPal
                      v-if="paypal_env == 'sandbox'"
                      :amount="cart.cart_amount.payable"
                      :currency=restaurant_detail.payment_currency
                      :client="paypal"
                      env="sandbox"
                      v-on:payment-completed="paymentSuccess"
                      :button-style="paypal_style"
                  >
                  </PayPal>

                  <PayPal
                      v-if="paypal_env == 'production'"
                      :amount="cart.cart_amount.payable"
                      :currency=restaurant_detail.payment_currency
                      :client="paypal"
                      v-on:payment-completed="paymentSuccess"
                      :button-style="paypal_style"
                  >
                  </PayPal>


                </div>
                <button


                    v-if="!processing && order.payment_method != 'ONLINE'"
                    type="button"
                    class="button is-success is-fullwidth"
                    @click="checkout"
                >Checkout
                </button>

                <button
                    v-if="processing && order.payment_method != 'ONLINE'"
                    type="button"
                    disabled="true"
                    class="button is-success is-fullwidth"
                >Processing Order...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import PayPal from 'vue-paypal-checkout'

export default {
  name: "MainCheckoutFormComponent",
  computed: mapState(["restaurant_detail", "cart", "table_no"]),
  components: {
    PayPal
  },
  data() {
    return {
      paypal_env: 'sandbox',
      paypal: {
        sandbox: '',
        production: '',
      },
      valid_form: false,
      paypal_style: {
        label: 'checkout',
        size: 'responsive',
        shape: 'pill',
        color: 'blue'
      },
      mql: true,
      user: {
        name: "",
        phone: "",
        email: "",
      },
      processing: false,

      order: {
        order_time: "",
        payable_amount: "",
        sub_total: "",
        order_type: "DINE_IN",
        restaurant_id: 0,
        table_no: "",
        payment_method: "CASH",
        note: "",
        payment_status: '',
        amount_paid: '',
        paypal_transaction_id: '',

      },
      food_list: {},
      order_transaction: {
        amount_paid: 0,
        env: '',
        currency: '',
        status: '',
        capture_id: ''

      },
      validation_messages: {
        email: [],
        name: [],
        phone: [],
        table_no: [],
        payment_method: [],
        note: [],
      },
    };
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.validate_form();
      }
    },
    user: {
      deep: true,
      handler() {
        this.validate_form();
      }
    },

  },
  methods: {
    paymentSuccess(status) {
      console.log(status)
      this.order_transaction.amount_paid = status.transactions[0].amount.total;
      this.order_transaction.currency = status.transactions[0].amount.currency;
      this.order_transaction.env = this.paypal_env;
      this.order_transaction.status = 'success';
      this.order_transaction.capture_id = status.transactions[0].related_resources[0].sale.id;

      console.log(this.order_transaction);

      this.order.payment_status = 'SUCCESS'
      this.order.paypal_transaction_id = status.id;
      this.order.amount_paid = status.transactions[0].amount.total;
      this.checkout();
    },
    validate_form() {

      if (this.restaurant_detail.subdomain_required_customer_phone)
        if (!this.user.phone)
          return this.valid_form = false;
      if (this.restaurant_detail.subdomain_required_customer_name)
        if (!this.user.name)
          return this.valid_form = false;


      if (this.restaurant_detail.subdomain_required_table_no)
        if (!this.order.table_no)
          return this.valid_form = false;


      if (this.restaurant_detail.subdomain_required_special_instruction)
        if (!this.order.note)
          return this.valid_form = false;


      if (this.restaurant_detail.subdomain_required_customer_email)
        if (!this.user.email)
          return this.valid_form = false;


      return this.valid_form = true;


    },
    current_time() {
      let time = new Date();
      return time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    },
    setOrder() {
      this.order.order_time = this.current_time();
      this.order.order_type = "DINE_IN";
      this.order.payable_amount = this.cart.cart_amount.payable;
      this.order.sub_total = this.cart.cart_amount.payable;
      this.order.restaurant_id = this.restaurant_detail.id;
    },
    setFoodList() {
      this.food_list = this.cart.food_list;
    },
    checkout() {
      this.setOrder();
      this.setFoodList();

      this.processing = true;
      let self = this;

      // return;
      axios
          .post(`${this.$store.getters.base_url}/api/embed/sub_domain_order`, {
            user: self.user,
            order: self.order,
            food_list: self.food_list,
            order_transaction: self.order_transaction,
          })
          .then((res) => {
            if (res.data.validation_error) {
              self.processing = false;

              self.validation_messages = res.data.message;
              return;
            }
            self.processing = false;
            self.$emit("setCurrentBox", "success");
            self.$store.commit("resetCart");
          });
    },
  },
  mounted() {
    if (this.restaurant_detail.paypal_sandbox_active) {
      this.paypal.sandbox = this.restaurant_detail.paypal_client_id;
      this.paypal_env = 'sandbox'
    } else {
      this.paypal.production = this.restaurant_detail.paypal_client_id;
      this.paypal_env = 'production'
    }


    if (!(this.cart.food_list.length && this.restaurant_detail.cart_feature))
      location.href = "/";
    this.mql = window.matchMedia("(max-width: 600px)").matches;
    if (this.table_no != null)
      this.order.table_no = this.table_no;

  },
};
</script>

<style lang='scss' scoped>
.form-holder {
  padding: 3rem 1rem;

  h2 {
    font-size: 30px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
  }

  label {
    color: #fff;
    font-size: 18px;
  }
}

.hero {
  .hero-body {
    padding-top: 0;
    padding-bottom: 10vw;
  }
}

.text-danger {
  color: red;
}
</style>