<template>
  <div class="theme-four">
    <t4topnav />
    <div class="detail-content">
      <div class="customer-logo">
        <figure class="image">
          <img :src="`${this.$store.getters.base_url}/${restaurant_detail.logo}`" style="width: 200px"
            alt="customerlogo" />
        </figure>
      </div>
      <p class="restaurant-description">
        {{ restaurant_detail.description }}
      </p>

      <div class="columns is-centered is-mobile is-marginless" v-if="!showTitle && restaurant_detail.theme.id == 4">
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.facebook">
          <a :href="'//' + restaurant_detail.facebook" target="_blank">
            <figure class="image is-32x32">
              <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/facebook.svg`" alt />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.insta">
          <a :href="'//' + restaurant_detail.insta" target="_blank">
            <figure class="image is-32x32">
              <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/instagram.svg`" alt />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.snapchat">
          <a :href="'//' + restaurant_detail.snapchat" target="_blank">
            <figure class="image is-32x32">
              <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/snapchat.svg`" alt />
            </figure>
          </a>
        </div>


        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.x_twitter">
          <a :href="'//' + restaurant_detail.x_twitter" target="_blank">
            <figure class="image is-32x32">
              <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/x_twitter.svg`" alt />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.tiktok">
          <a :href="'//' + restaurant_detail.tiktok" target="_blank">
            <figure class="image is-32x32">
              <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/tiktok.png`" alt />
            </figure>
          </a>
        </div>

        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.google_reviews">
          <a :href="'//' + restaurant_detail.google_reviews" target="_blank">
            <figure class="image is-32x32">
              <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/google_reviews.png`" alt />
            </figure>
          </a>
        </div>


      </div>

      <router-link to="/menu" class="column has-text-centered menu-btn"
        v-if="restaurant_detail.theme_setting.menu_view != 'image'">
        <span>{{ restaurant_detail.localization.menu_home_menu }}</span>
      </router-link>

      <a :href="`/menu?lang=${locale}`" class="column has-text-centered menu-btn"
        v-if="restaurant_detail.theme_setting.menu_view == 'image'">
        <span>{{ restaurant_detail.localization.menu_home_menu }}</span>
      </a>
    </div>
    <div class="t4bottom-nav">
      <div class="link" v-if="restaurant_detail.waiter_feature">
        <router-link to="/restaurant/callforwaiter/">
          <img :src="`${this.$store.getters.base_url}/assets/images/subdomain_theme/four/call_waiter.png`" width="40"
            alt />
          {{ restaurant_detail.localization.menu_home_call_waiter }}
        </router-link>
      </div>
      <div class="link" v-if="restaurant_detail.feedback_feature">
        <router-link to="/restaurant/feedback/">
          <img :src="`${this.$store.getters.base_url}/assets/images/subdomain_theme/four/reviews.png`" width="40" alt />
          {{ restaurant_detail.localization.menu_home_reviews }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import t4topnav from "./topNav";
export default {
  name: "themefourHeader",
  computed: mapState(["restaurant_detail", "locale"]),

  components: {
    t4topnav,
  },
};
</script>

<style lang='scss' scoped>
.theme-four {
  position: relative;

  .detail-content {
    display: flex;
    flex-direction: column;
    padding: 50px 0;

    .menu-btn {
      margin-top: 50px;

      span {
        background: #0a0a0a82;
        padding: 10px 20px;
        border-radius: 50px;
        color: #fff;
        font-size: 26px;
        font-weight: bold;
      }
    }
  }

  .t4bottom-nav {
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
      padding-right: 10px;
      border-right: 1px solid #1a1a1a;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      & a {
        color: #1a1a1a;

        display: flex;
        align-items: center;

        & img {
          margin-right: 10px;
        }
      }

      &:last-child {
        border: none;
        padding-left: 10px;
      }
    }
  }
}
</style>