export default {
    menu: state => state.menu,
    restaurant_detail: state => state.restaurant_detail,
    base_url: state => "https://eazyfnb.com",
    selected_food_item: state => state.selected_food_item,
    recommended: state => state.recommended,
    locale: state => state.locale,
    fav_items: state => state.fav_items,
    table_no: state => state.table_no,
    subdomain_url: state => state.subdomain_url,
    cart: state => {
        return state.cart;
    }
};
