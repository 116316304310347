<template>
  <div class="callWaiterParent">
    <div class="container">
      <btnsNav />
    </div>
    <checkoutForm v-on:setCurrentBox="setCurrentBox" v-if="current_box == 'form'" />
    <successBox v-if="current_box == 'success'" />
    <appNav
      :route_back="{
        name: 'MenuParentHomeComponent',
        params: {},
        display_name: restaurant_detail.localization.misc_home

      }"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import checkoutForm from "@/components/checkout/Form";
import successBox from "@/components/checkout/Success";
import appNav from "@/components/layouts/AppNav";
import btnsNav from "@/components/ui/BtnsNav";

export default {
  name: "checkoutParentView",
  components: {
    checkoutForm,
    successBox,
    appNav,
    btnsNav,
  },
  computed: mapState(["restaurant_detail"]),
  data() {
    return {
      logo: "",
      current_box: "form",
    };
  },
  methods: {
    setHeaderDetail() {
      this.logo = `${this.$store.getters.base_url}/${this.restaurant_detail.logo}`;
    },
    setCurrentBox(box) {
      this.current_box = box;
    },
  },
  mounted() {
    this.setHeaderDetail();
  },
};
</script>

<style lang="scss" scoped>
.callWaiterParent {
  .large-logo-detail {
    width: 150px;
    height: 150px;
  }

  height: 100vh;
}
</style>
