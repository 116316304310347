var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.restaurant_detail.theme.id != 4)?_c('detailHeader',{attrs:{"heading":"Menu"}}):_vm._e(),(_vm.restaurant_detail.theme.id === 4)?_c('t4Topnav'):_vm._e(),(
      _vm.restaurant_detail.theme_setting.menu_view != 'list'
    )?_c('menuCategories'):_vm._e(),(
      _vm.restaurant_detail.theme_setting.menu_view == 'list'
    )?_c('listView'):_vm._e(),_c('mainFooter'),_c('bottomNav',{attrs:{"route_back":{
      name: 'MenuParentHomeComponent',
      params: {},
      display_name: _vm.restaurant_detail.localization.misc_home,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }