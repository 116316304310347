<template>
  <div>
    <figure class="image is-128x128 rest-logo">
      <img :src="`${$store.getters.base_url}/${restaurant_detail.logo}`" />
    </figure>
    <p class="restaurant-description">{{ restaurant_detail.description }}</p>
    <div class="horizontal-sections">
      <div class="foodsec" v-for="section of categories">
        <figure class="image" @click="filterByCategory(section)">
          <img
            class="is-rounded"
            :src="`${$store.getters.base_url}/${section.image}`"
          />
        </figure>
        <p>{{ section.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ThemeFourHorizontalScrollSection",
  computed: mapState(["menu", "restaurant_detail"]),
  watch: {
    menu(menu) {
      this.categories = menu;
    },
  },
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.categories = this.menu;
  },
  methods: {
    filterByCategory(category) {
      if (!category.sub_section.length) {
        this.$router.push(`/menu/${category.id}/food_items`);
        location.reload();
        return;
      }

      this.$router.push(`/submenu/${category.id}`);
      location.reload();

      return;
      this.$store.commit("theme_four_selected_section", category);
      if (this.$route.name != "MainMenuCategoriesComponent")
        this.$router.push({ name: "MainMenuCategoriesComponent" });
    },
  },
};
</script>

<style lang='scss' scoped>
figure.rest-logo {
  margin-top: 20px !important;
}
.restaurant-description {
  text-align: center;
  color: #fff;
}
.horizontal-sections {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
  & .foodsec {
    flex: 0 0 auto;
    cursor: pointer;
    margin-left: 20px;
    text-align: center;
    &:last-child {
      padding-right: 20px;
    }
    & p {
      color: #fff;
      font-size: 14px;
      margin-top: 10px;
      font-weight: bold;
    }
    figure {
      width: 76px;
      height: 76px;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>