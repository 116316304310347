<template>
  <div>
    <t4topnav v-if="restaurant_detail.theme.id === 4" />
    <t4HorizontalSec v-if="restaurant_detail.theme.id === 4" />
    <div class="submenu-holder">
      <div class="container">
        <div class="columns is-multiline">
          <router-link
            :to="`/submenu/${subSection.id}/fooditems`"
            class="column is-3 has-text-centered"
            v-for="subSection in subSections"
            :key="subSection.id"
          >
            <figure class="image is-5by4">
              <v-lazy-image
                :src="`${$store.getters.base_url}/${subSection.image}`"
                alt
              />
            </figure>
            <span>{{ subSection.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
import { mapState } from "vuex";
import t4topnav from "@/components/layouts/theme-four/topNav";
import t4HorizontalSec from "@/components/layouts/theme-four/horizontalSection";

export default {
  name: "MainSubMenuComponent",
  components: {
    VLazyImage,
    t4topnav,
    t4HorizontalSec,
  },
  computed: mapState(["menu","restaurant_detail"]),
  watch: {
    menu(menu) {
      this.setSubSections();
    },
  },
  methods: {
    setSubSections() {
      this.subSections = [];
      let self = this;
      let section = this.menu.filter((menu) => menu.id == self.category_id);
      if (section.length) {
        if (section[0].sub_section.length) {
          self.subSections = section[0].sub_section;
          console.log(self.subSections);
        }
      }
    },
  },
  data() {
    return {
      category_id: this.$route.params.category_id,
      subSections: [],
    };
  },
  mounted() {
    this.setSubSections();
  },
};
</script>

<style lang='scss' scoped>
.submenu-holder {
  padding: 1rem 3rem 1rem 3rem;

  & .columns {
    & .column {
      border: 1px solid #fff;
      position: relative;

      & figure {
        margin-bottom: 12%;
      }

      & span {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 18px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;

        width: 100%;
        text-align: center;
        background-color: rgba(20, 6, 2, 0.6);
        padding: 3px 0;
      }
    }
  }
}
</style>