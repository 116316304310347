<template>
  <div class="form-holder">
     <figure class="image is-128x128 rest-logo" v-if="restaurant_detail.theme.id == 4"  >
      <img :src="`${$store.getters.base_url}/${restaurant_detail.logo}`" />
    </figure>
      <p class="restaurant-description">{{ restaurant_detail.description }}</p>

      <!-- <div class="columns is-centered is-mobile is-marginless" v-if="!showTitle && restaurant_detail.theme.id == 4 "  >
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.facebook">
          <a :href="'//'+restaurant_detail.facebook" target="_blank">
            <figure class="image is-32x32">
              <img
                class="social-icons"
                :src="`${$store.getters.base_url}/embed/images/facebook.svg`"
                alt
              />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.insta">
          <a :href="'//'+restaurant_detail.insta" target="_blank">
            <figure class="image is-32x32">
              <img
                class="social-icons"
                :src="`${$store.getters.base_url}/embed/images/instagram.svg`"
                alt
              />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.snapchat">
          <a :href="'//'+restaurant_detail.snapchat" target="_blank">
            <figure class="image is-32x32">
              <img
                class="social-icons"
                :src="`${$store.getters.base_url}/embed/images/snapchat.svg`"
                alt
              />
            </figure>
          </a>
        </div>
      </div> -->
    <div class="container">
      <div class="columns is-desktop">
        <div class="column has-text-centered">
          <h2> {{ restaurant_detail.localization.call_waiter_call_for_waiter }}</h2>
        </div>
      </div>
      <div class="columns is-desktop is-centered">
        <div class="column is-6 has-text-centered">
          <div class="field" v-if="!table_no">
            <label class="label">{{ restaurant_detail.localization.call_waiter_table_number }}</label>

            <div class="control">
              <input


                  class="input is-primary"
                  type="text"
                  v-model="tableno"
                  placeholder="Table Number"
              />
            </div>
          </div>
          <button v-if="!processing"
                  class="button is-primary is-fullwidth" @click="callWaiter">
            {{ restaurant_detail.localization.call_waiter_call }}
          </button>

          <button v-if="processing"
                  class="button is-primary is-fullwidth" disabled>
            Processing...
          </button>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
  name: "MainCallToWaiterFormComponent",
  computed: mapState(["restaurant_detail", "table_no"]),
  data() {
    return {
      processing: false,

      tableno: "",
      restaurant_id: 0
    };
  },
  methods: {
    callWaiter() {
      let self = this;

      if (this.table_no) {
        this.tableno = this.table_no;
      }

      if (!self.tableno) return;

      this.processing = true;


      axios
          .post(`${this.$store.getters.base_url}/api/call_waiter`, {
            restaurant_id: self.restaurant_id,
            tableno: self.tableno
          })
          .then(res => {
            self.processing = false;

            self.$vToastify.success({
                  title: "Request Sent Successfully",
                  body: "Waiter will be on your table soon",
                  type: "success",
                  theme: "light"
                }
            );
            self.tableno = '';
          });
    }
  },
  mounted() {
    this.restaurant_id = this.restaurant_detail.id;
  }
};
</script>

<style lang='scss' scoped>
.form-holder {
  padding: 3rem 1rem;

  h2 {
    font-size: 30px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
  }

  label {
    color: #fff;
    font-size: 18px;
  }
}
</style>