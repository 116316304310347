<template>
  <div class="detail-header">
    <div class="container">
      <btnsNav />
      <dropDownNav v-if="restaurant_detail.theme_setting.menu_view != 'list'" />

      <!-- <div class="columns is-desktop is-centered">
        <div class="column has-text-centered detail-title">
          <h2>{{restaurant_detail.name}} {{heading}}</h2>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dropDownNav from "./DropDownNav";
import btnsNav from "@/components/ui/BtnsNav";

export default {
  name: "DetailHeaderComponent",
  components: {
    dropDownNav,
    btnsNav,
  },
  computed: mapState(["restaurant_detail"]),
  props: ["heading"],
  data() {
    return {
      logo: "",
    };
  },
  methods: {
    setHeaderDetail() {
      this.logo = `${this.$store.getters.base_url}/${this.restaurant_detail.logo}`;
    },
  },
  mounted() {
    this.setHeaderDetail();
  },
};
</script>

<style lang='scss' scoped>
.detail-header {
  .large-logo-detail {
    width: 150px;
    height: 150px;
  }
  .detail-title {
    border-bottom: 1px solid #fff;

    h2 {
      color: #fff;
      font-size: 30px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}
</style>