import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
import mutations from "./mutations";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});
Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        theme_four_selected_section: {},
        table_no: null,
        base_url: "https://eazyfnb.com",
        // base_url: "http://localhost:8000",
        subdomain_url: '',
        menu: [
            {
                id: 0,
                title: "",
                count: 0,
                image: "",
                sub_section: [],
                food_items: []
            }
        ],
        recommended: [
            {
                route: "",
                image: "",
                name: ""
            }
        ],
        currentLanguage: {
            code: "en"
        },
        locale: "en",
        restaurant_detail: {
            id: 0,
            name: "",
            logo: "",
            description: "",
            background_image: "",
            menu_feature: true,
            waiter_feature: true,
            feedback_feature: true,
            cart_feature: false,
            onlinelink: "",
            orderonline_feature: false,
            facebook: "",
            insta: "",
            snapchat: "",
            languages: [{ id: 0, code: "en", name: "English" }],
            theme: {
                id: 1
            },
            theme_setting: {},
            checkout_message: '',
            paypal_client_id: '',
            paypal_active: false,
            payment_card: false,
            payment_cash: false,
            paypal_sandbox_active: false,
            subdomain_required_table_no: true,
            subdomain_required_customer_name: true,
            subdomain_required_customer_email: false,
            subdomain_required_customer_phone: false,
            subdomain_required_special_instruction: false,
            payment_currency: 'USD',
            localization: {
                menu_home_menu: '',
                menu_home_reviews: '',
                menu_home_call_waiter: '',
                menu_item_name: '',
                menu_price: '',
                menu_add_to_cart: '',
                menu_item_description: '',
                menu_recommended: '',
                checkout_table_no: '',
                checkout_customer_name: '',
                checkout_phone: '',
                checkout_special_instructions: '',
                checkout_payment_method: '',
                checkout_card: '',
                checkout_cash: '',
                checkout_paypal: '',
                checkout_email: '',
                reviews_ambiance: '',
                reviews_food: '',
                reviews_service: '',
                reviews_phone: '',
                reviews_staff: '',
                misc_go_to_your_favourite: 'Go To Your Favourite',
                misc_change_language: 'Change Language',
                misc_home: 'Home',
                misc_back: 'Back',
                misc_order_online: 'Order Online',
                reviews_form_customer_name: 'Customer Name',
                reviews_form_email: 'Email',
                reviews_form_comment: 'Comment',
                call_waiter_call_for_waiter: 'Call For Waiter',
                call_waiter_table_number: 'Table #',
                call_waiter_call: 'Call',
            }
        },

        food_item: {
            id: 0,
            name: "",
            price: 0,
            description: "",
            image: "",
            customize: 0,
            variations: [],
            restaurant_id: 0,
            food_type: "",
            menu_section_id: 0,
            menu_sub_section_id: 0,
            food_attributes: []
        },
        cart:
        {
            active: false,
            restaurant_id: 0,
            restaurant_detail: {
                name: '',
                address: '',
                image: '',
            },
            restaurant_slug: '--',
            food_list: [],
            cart_amount: {
                payable: 0
            },

        },
        fav_items: [],
    },
    getters,
    mutations
});
