<template>
  <div class="review-form-holder">
    <figure class="image is-128x128 rest-logo" v-if="restaurant_detail.theme.id == 4">
      <img :src="`${$store.getters.base_url}/${restaurant_detail.logo}`" />
    </figure>
    <p class="restaurant-description">{{ restaurant_detail.description }}</p>

    <div class="columns is-centered is-mobile is-marginless" v-if="!showTitle && restaurant_detail.theme.id == 4">
      <div class="column is-narrow has-text-centered" v-if="restaurant_detail.facebook">
        <a :href="'//' + restaurant_detail.facebook" target="_blank">
          <figure class="image is-32x32">
            <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/facebook.svg`" alt />
          </figure>
        </a>
      </div>
      <div class="column is-narrow has-text-centered" v-if="restaurant_detail.insta">
        <a :href="'//' + restaurant_detail.insta" target="_blank">
          <figure class="image is-32x32">
            <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/instagram.svg`" alt />
          </figure>
        </a>
      </div>
      <div class="column is-narrow has-text-centered" v-if="restaurant_detail.snapchat">
        <a :href="'//' + restaurant_detail.snapchat" target="_blank">
          <figure class="image is-32x32">
            <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/snapchat.svg`" alt />
          </figure>
        </a>
      </div>


      <div class="column is-narrow has-text-centered" v-if="restaurant_detail.x_twitter">
        <a :href="'//' + restaurant_detail.x_twitter" target="_blank">
          <figure class="image is-32x32">
            <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/x_twitter.svg`" alt />
          </figure>
        </a>
      </div>
      <div class="column is-narrow has-text-centered" v-if="restaurant_detail.tiktok">
        <a :href="'//' + restaurant_detail.tiktok" target="_blank">
          <figure class="image is-32x32">
            <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/tiktok.png`" alt />
          </figure>
        </a>
      </div>

      <div class="column is-narrow has-text-centered" v-if="restaurant_detail.google_reviews">
        <a :href="'//' + restaurant_detail.google_reviews" target="_blank">
          <figure class="image is-32x32">
            <img class="social-icons" :src="`${$store.getters.base_url}/embed/images/google_reviews.png`" alt />
          </figure>
        </a>
      </div>


    </div>
    <div class="container">
      <div class="columns is-mobile">
        <div class="column">
          <span class="rating-type">{{
            restaurant_detail.localization.reviews_ambiance
          }}</span>
        </div>
        <div class="column">
          <star-rating :star-size="26" v-model="form_data.ambiance" :show-rating="false"></star-rating>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column">
          <span class="rating-type">{{
            restaurant_detail.localization.reviews_food
          }}</span>
        </div>
        <div class="column">
          <star-rating :star-size="26" v-model="form_data.food" :show-rating="false"></star-rating>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column">
          <span class="rating-type">{{
            restaurant_detail.localization.reviews_service
          }}</span>
        </div>
        <div class="column">
          <star-rating :star-size="26" v-model="form_data.service" :show-rating="false"></star-rating>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column">
          <span class="rating-type">{{
            restaurant_detail.localization.reviews_staff
          }}</span>
        </div>
        <div class="column">
          <star-rating :star-size="26" v-model="form_data.staff" :show-rating="false"></star-rating>
        </div>
      </div>

      <div class="columns is-multiline">
        <div class="column is-12-mobile is-6-tablet is-6-desktop">
          <div class="field">
            <label class="label">{{
              restaurant_detail.localization.reviews_form_customer_name
            }}</label>
            <div class="control">
              <input v-model="form_data.name" class="input is-primary" type="text" placeholder="Name" required />

              <span style="color: red" v-for="error in validation_messages.name">
                {{ error }}
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="column is-12-mobile is-6-tablet is-6-desktop">
          <div class="field">
            <label class="label">{{
              restaurant_detail.localization.reviews_form_email
            }}</label>
            <div class="control">
              <input v-model="form_data.email" class="input is-primary" type="email" placeholder="Enter your email"
                required />
              <span style="color: red" v-for="error in validation_messages.email">
                {{ error }}
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="column is-12-mobile is-6-tablet is-6-desktop">
          <div class="field">
            <label class="label">{{
              restaurant_detail.localization.reviews_form_comment
            }}</label>
            <div class="control">
              <textarea v-model="form_data.comment" class="textarea is-primary"
                placeholder="Type your review and comment for restaurant here."></textarea>
            </div>
          </div>
        </div>

        <div class="column is-12-mobile is-6-tablet is-6-desktop">
          <button class="button is-primary is-fullwidth" @click="storeFeedback">
            Post Review
          </button>
        </div>

        <div class="column is-12-mobile is-6-tablet is-6-desktop testimonials">
          <vueper-slides :autoplay="this.autoplay">
            <vueper-slide v-for="(feedback, i) in feedbacks" :key="i" :title="feedback.name"
              :content="feedback.comment" />
          </vueper-slides>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "MainReviewFormComponent",
  computed: mapState(["restaurant_detail"]),

  components: {
    StarRating,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      validation_messages: {
        email: [],
        name: [],
      },
      autoplay: true,
      form_data: {
        ambiance: 0,
        food: 0,
        service: 0,
        staff: 0,
        name: "",
        email: "",
        comment: "",
        restaurant_id: 0,
      },
      feedbacks: [
        {
          ambiance: 0,
          food: 5,
          service: 3,
          staff: 3,
          name: "",
          comment: "",
        },
      ],
      slides: [
        {
          title: "Slide #1",
          content: "Slide content.",
        },
        {
          title: "Slide #2",
          content: "Slide content.",
        },
      ],
    };
  },
  methods: {
    storeFeedback() {
      this.form_data.restaurant_id = this.restaurant_detail.id;
      let self = this;
      axios
        .post(`${this.$store.getters.base_url}/api/feedback`, self.form_data)
        .then((res) => {
          if (res.data.validation_error) {
            self.validation_messages = res.data.message;
            return;
          }
          self.form_data = {
            ambiance: 0,
            food: 0,
            service: 0,
            staff: 0,
            name: "",
            email: "",
            comment: "",
            restaurant_id: 0,
          };

          self.$vToastify.success({
            title: "Success",
            body: "Thank you For Your Feedback",
            type: "success",
            theme: "light",
          });
        });
    },
    getFeedbacks() {
      let self = this;
      axios
        .get(
          `${this.$store.getters.base_url}/api/feedback?restaurant_id=${self.restaurant_detail.id}`
        )
        .then((res) => {
          self.feedbacks = res.data.feedbacks;
          console.log(self.feedbacks);
        });
    },
  },
  mounted() {
    this.getFeedbacks();
  },
};
</script>

<style lang="scss" scoped>
.review-form-holder {
  padding: 1.5rem;

  .rating-type {
    color: #fff;
    font-size: 22px;
  }

  .field {
    .label {
      color: #fff;
    }
  }

  .testimonials {
    color: #fff;
  }
}
</style>
