<template>
  <div>
    <t4topnav v-if="restaurant_detail.theme.id === 4" />
    <t4HorizontalSec v-if="restaurant_detail.theme.id === 4" />
    <div class="food-items-holder">
      <div class="container">
        <div class="columns is-multiline">
          <router-link
            :to="`/submenu/${sub_section_id}/fooditems/${foodItem.id}`"
            class="column is-3 has-text-centered"
            v-for="foodItem in foodItems"
            :key="foodItem.id"
          >
            <figure class="image is-5by4" @click="setItem(foodItem)">
              <v-lazy-image
                :src="`${$store.getters.base_url}/${foodItem.image}`"
                alt
              />
            </figure>
            <span
              >{{ foodItem.name }} -
              <small>{{ foodItem.currency.prefix }}</small>
              {{ foodItem.price }}
              <small>{{ foodItem.currency.suffix }}</small></span
            >
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
import { mapState } from "vuex";
import t4topnav from "@/components/layouts/theme-four/topNav";
import t4HorizontalSec from "@/components/layouts/theme-four/horizontalSection";

export default {
  name: "MainFoodItemsComponent",
  components: {
    VLazyImage,
    t4topnav,
    t4HorizontalSec,
  },
  computed: mapState(["menu", "restaurant_detail"]),
  watch: {
    menu(menu) {
      this.setFoodItems();
    },
  },
  methods: {
    setFoodItems() {
      this.foodItems = [];
      let self = this;
      let flag = 0;
      for (let i = this.menu.length - 1; i >= 0; i--) {
        for (let j = this.menu[i].sub_section.length - 1; j >= 0; j--) {
          if (this.menu[i].sub_section[j].id == this.sub_section_id) {
            this.foodItems = this.menu[i].sub_section[j].food_items;
            flag = i;
            ``;
          }
        }
      }

      this.$emit("setSubsectonId", this.menu[flag].id);
    },
    setItem(foodItem) {},
  },
  data() {
    return {
      sub_section_id: this.$route.params.sub_section_id,
      subSections: [],
      foodItems: [],
    };
  },
  mounted() {
    this.setFoodItems();
  },
};
</script>

<style lang='scss' scoped>
.food-items-holder {
  padding: 1rem 1rem 1rem 1rem;

  & .columns {
    & .column {
      border: 1px solid #fff;
      position: relative;

      & figure {
        margin-bottom: 12%;
      }

      & span {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 18px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;

        width: 100%;
        text-align: center;
        background-color: rgba(20, 6, 2, 0.6);
        padding: 3px 0;
      }
    }
  }
}
</style>