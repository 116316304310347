import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

import "./assets/css/main.scss";

import "./assets/js/handy-collapse.js";

// widget setup
// (optional) 'Custom elements polyfill'
import "document-register-element/build/document-register-element";
// vue-custom-element by karol-f
import vueCustomElement from "vue-custom-element";
import VueToastify from "vue-toastify";
import VueSocialSharing from "vue-social-sharing";

Vue.use(vueCustomElement);
Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

// use vue-custom-element
App.store = store;
App.router = router;
Vue.use(VueToastify);
Vue.customElement("foody-menu-widget", App);
