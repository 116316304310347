<template>
  <nav class="mobile-bottom-nav">
    <!-- <div
      class="mobile-bottom-nav__item"
      v-if="
        restaurant_detail.languages.length > 1 &&
        restaurant_detail.theme.id == 3
      "
    >
      <div class="dropdown is-up mobile-bottom-nav__item-content">
        <div class="dropdown-trigger">
          <button
            class="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu7"
          >
            <span>{{
              restaurant_detail.localization.misc_change_language
            }}</span>
            <span class="icon is-small">
              <i class="fas fa-angle-up" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu7" role="menu">
          <div class="dropdown-content">
            <a
              @click="setLanguage(language.code)"
              class="dropdown-item"
              v-for="language in restaurant_detail.languages"
              :key="language.id"
              >{{ language.name }}</a
            >
          </div>
        </div>
      </div>
    </div> -->

    <div
      class="mobile-bottom-nav__item"
      v-if="
        restaurant_detail.onlinelink && restaurant_detail.orderonline_feature
      "
    >
      <a
        :href="'//' + restaurant_detail.onlinelink"
        target="_blank"
        class="mobile-bottom-nav__item-content"
        >{{ restaurant_detail.localization.misc_order_online }}</a
      >
    </div>
    <!-- <div
      class="mobile-bottom-nav__item"
      v-if="
        ($route.name == 'sub_section_food_item' ||
        $route.name == 'section_food_item') && restaurant_detail.theme.id == 4
      "
    >
      <router-link to="/menu" class="mobile-bottom-nav__item-content"
        >{{ route_back.display_name }}
      </router-link>
    </div> -->
    <div
      class="mobile-bottom-nav__item">
      <router-link
        :to="{ name: route_back.name, params: route_back.params }"
        class="mobile-bottom-nav__item-content"
        >{{ route_back.display_name }}
      </router-link>
    </div>
  </nav>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "MainBottomNav",
  computed: mapState(["restaurant_detail"]),
  props: ["route_back"],
  data() {
    return {
      languages: [
        {
          id: 1,
          code: "en",
          name: "English",
        },
      ],
    };
  },
  methods: {
    redirectToPreviousRoute() {
      this.$router.push({
        name: this.route_back.name,
        params: this.route_back.params,
      });
    },
    setLanguage(code) {
      this.$store.commit("locale", code);
    },
  },
  mounted() {
    try {
      if (this.restaurant_detail.languages.length > 1) {
        let dropdown = document.querySelector(".dropdown");
        dropdown.addEventListener("click", function (event) {
          event.stopPropagation();
          dropdown.classList.toggle("is-active");
        });
      }
    } catch (e) {}
  },
};
</script>

<style lang="scss" scoped>
.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  //give nav it's own compsite layer
  will-change: transform;
  transform: translateZ(0);

  display: flex;

  height: 50px;

  box-shadow: 0 -2px 5px -2px #333;
  background-color: #fff;
  cursor: pointer;

  &__item {
    flex-grow: 1;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 33%;
    border-right: 1px solid #9e9e9e;

    &:last-child {
      border-right: none;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    color: #1a1a1a;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
