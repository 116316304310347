<template>
  <div class="nav-holder">
    <div class="container">
      <div class="columns is-centered is-desktop">
        <div v-if="restaurant_detail.menu_feature">
          <router-link
            to="/menu"
            class="column has-text-centered menu-btn"
            v-if="restaurant_detail.theme_setting.menu_view != 'image'"
          >
            <span>{{ restaurant_detail.localization.menu_home_menu }}</span>
          </router-link>

          <a
            :href="`/menu?lang=${locale}`"
            class="column has-text-centered menu-btn"
            v-if="restaurant_detail.theme_setting.menu_view == 'image'"
          >
            <span>{{ restaurant_detail.localization.menu_home_menu }}</span>
          </a>
        </div>
        <router-link
          to="/restaurant/callforwaiter/"
          class="column has-text-centered"
          v-if="restaurant_detail.waiter_feature"
        >
          <figure class="image container is-64x64">
            <img
              :src="`${this.$store.getters.base_url}/assets/images/subdomain_theme/call_waiter.png`"
              alt
            />
          </figure>
          <span>{{
            restaurant_detail.localization.menu_home_call_waiter
          }}</span>
        </router-link>
        <router-link
          to="/restaurant/feedback/"
          class="column has-text-centered"
          v-if="restaurant_detail.feedback_feature"
        >
          <figure class="image container is-64x64">
            <img
              :src="`${this.$store.getters.base_url}/assets/images/subdomain_theme/reviews.png`"
              alt
            />
          </figure>
          <span>{{ restaurant_detail.localization.menu_home_reviews }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MenuThemeTwoNavComponent",
  computed: mapState(["restaurant_detail","locale"]),
};
</script>

<style lang="scss" scoped>
.nav-holder {
  padding: 3rem 0;

  & .column {
    border-right: 1px solid #fff;

    img {
      background: none;
    }

    & span {
      font-size: 26px;
      color: #fff;
    }

    &:hover {
      transform: translateY(-15px);
    }

    &:last-child {
      border: none;
    }

    &:first-child {
      padding-bottom: 50px;

      span {
        background: #0a0a0a82;
        padding: 5px 20px;
        border-radius: 50px;
      }

      &:hover {
        transform: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .nav-holder {
    padding: 0;

    & .menu-btn {
      border-bottom: none !important;
    }

    & .column {
      border-right: none;
      border-top: 1px solid #fff;

      &:first-child {
        border-top: none !important;
      }

      &:last-child {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
      }
    }
  }
}
</style>
