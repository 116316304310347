<template>
  <div class="list-holder">
    <div class="container">
      <div class="columns">
        <div class="column is-full">
          <section class="section">
            <!--  parent section-->

            <div
              class="section-holder"
              v-for="section in menu"
              :key="section.id"
            >
              <button
                type="button"
                class="button is-fullwidth acc-btn"
                :data-nested-control="`parent_section-${section.id}`"
              >
                {{ section.title }}
              </button>
              <div :data-nested-content="`parent_section-${section.id}`">
                <div class="box">
                  <!--sub section-->
                  <span
                    v-if="
                      !section.sub_section.length && !section.food_items.length
                    "
                    >No Record Found</span
                  >
                  <div
                    class="child"
                    v-for="sub_section in section.sub_section"
                    :key="sub_section.id"
                  >
                    <button
                      type="button"
                      class="button is-fullwidth acc-btn"
                      :data-nested-control="`sub_section-${sub_section.id}`"
                    >
                      {{ sub_section.title }}
                    </button>
                    <div :data-nested-content="`sub_section-${sub_section.id}`">
                      <div class="box" style="background-color: #fff">
                        <!--sub child   -->
                        <span v-if="!sub_section.food_items.length"
                          >No Record Found</span
                        >
                        <div
                          class="child"
                          v-for="item in sub_section.food_items"
                          :key="item.id"
                        >
                          <button
                            type="button"
                            class="button is-fullwidth acc-btn sub_button"
                            :data-nested-control="`sub_section-item-${item.id}`"
                          >
                            <span>{{ item.name }}</span>
                            <span class="price"
                              >{{ item.currency.prefix }} {{ item.price }}
                              {{ item.currency.suffix }}</span
                            >
                          </button>
                          <div
                            :data-nested-content="`sub_section-item-${item.id}`"
                          >
                            <div class="box" style="background-color: #fff">
                              <article class="media">
                                <figure class="media-left">
                                  <p class="image is-128x128">
                                    <img
                                      :src="`${$store.getters.base_url}/${item.image}`"
                                    />
                                  </p>
                                </figure>
                                <div class="media-content">
                                  <div class="content">
                                    <p>{{ item.description }}</p>
                                    <hr />
                                    <div
                                      class="field is-grouped is-grouped-multiline"
                                    >
                                      <div
                                        class="control"
                                        v-for="attb in item.food_attributes"
                                        :key="attb.id"
                                      >
                                        <div class="tags has-addons">
                                          <span class="tag is-dark">{{
                                            attb.name
                                          }}</span>
                                          <span class="tag is-info">{{
                                            attb.detail
                                          }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="media-right"
                                  v-if="restaurant_detail.cart_feature"
                                >
                                  <button
                                    class="button is-info order-btn"
                                    @click="addFoodItem(item)"
                                  >
                                    Order
                                  </button>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!section.sub_section.length">
                    <div
                      class="child"
                      v-for="item in section.food_items"
                      :key="item.id"
                    >
                      <button
                        type="button"
                        class="button is-fullwidth acc-btn"
                        :data-nested-control="`item-${item.id}`"
                      >
                        <span>{{ item.name }}</span>
                        <span class="price"
                          >{{ item.currency.prefix }} {{ item.price }}
                          {{ item.currency.suffix }}</span
                        >
                      </button>
                      <div :data-nested-content="`item-${item.id}`">
                        <div class="box" style="background-color: #fff">
                          <article class="media">
                            <figure class="media-left">
                              <p class="image is-128x128">
                                <img
                                  :src="`${$store.getters.base_url}/${item.image}`"
                                />
                              </p>
                              <!--                                                            <div class="buttons">-->
                              <!--                                                                <button class="button heart-btn">-->
                              <!--                                                                    <span style="color: blue">{{likes}}</span>-->
                              <!--                                                                    <span class="icon is-small">-->
                              <!--                                    <img-->
                              <!--                                            :src="`${$store.getters.base_url}/img/icons/heart-solid.svg`"-->
                              <!--                                    />-->
                              <!--                                  </span>-->
                              <!--                                                                </button>-->
                              <!--                                                                <button class="button share-btn">-->
                              <!--                                  <span class="icon is-small">-->
                              <!--                                    <img-->
                              <!--                                            :src="`${$store.getters.base_url}/img/icons/share-alt-solid.svg`"-->
                              <!--                                    />-->
                              <!--                                  </span>-->
                              <!--                                                                </button>-->
                              <!--                                                            </div>-->
                            </figure>
                            <div class="media-content">
                              <div class="content">
                                <p>{{ item.description }}</p>
                                <hr />

                                <div
                                  class="field is-grouped is-grouped-multiline"
                                >
                                  <div
                                    class="control"
                                    v-for="attb in item.food_attributes"
                                    :key="attb.id"
                                  >
                                    <div class="tags has-addons">
                                      <span class="tag is-dark">{{
                                        attb.name
                                      }}</span>
                                      <span class="tag is-info">{{
                                        attb.detail
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="media-right"
                              v-if="restaurant_detail.cart_feature"
                            >
                              <button
                                class="button is-info order-btn"
                                @click="addFoodItem(item)"
                              >
                                Order
                              </button>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <sweet-modal ref="variationModal" title="Select Variation">
      <p class="is-size-5 has-text-black-bis">{{ selectedFoodItem.name }}</p>
      <p>
        Price:
        {{ restaurant_detail.currency.prefix }}
        <span>{{ this.selectedFoodItem.calculated_price }}</span>
        {{ restaurant_detail.currency.suffix }}
      </p>
      <p style="margin-bottom: 20px">{{ selectedFoodItem.description }}</p>
      <div
        v-for="(variation, var_index) in selectedFoodItem.variations"
        :key="var_index"
      >
        <div class="notification">
          <p class="is-size-5 has-text-black">{{ variation.title }}</p>
        </div>
        <div class="columns is-mobile">
          <div
            class="column"
            v-for="(option, opt_index) in variation.options"
            :key="opt_index"
          >
            <div v-if="!checkBoxType(var_index)">
              <input
                type="radio"
                :id="`opt${option.id}`"
                @click="optionSelected(var_index, opt_index, $event, true)"
              />

              <label class="custom-control-label" :for="`opt${option.id}`">
                {{ option.name }}
                <span v-if="option.price != 0">
                  (
                  <small>{{ restaurant_detail.currency.suffix }}</small>
                  +{{ option.price }}
                  <small>{{ restaurant_detail.currency.suffix }}</small
                  >)
                </span>
              </label>
            </div>

            <div
              class="custom-control custom-radio"
              v-if="checkBoxType(var_index)"
            >
              <input
                type="checkbox"
                :id="`opt${option.id}`"
                :name="`var${var_index}`"
                @click="optionSelected(var_index, opt_index, $event)"
              />
              <label class="custom-control-label" :for="`opt${option.id}`">
                {{ option.name }}
                <span v-if="option.price != 0">
                  (
                  <small>{{ restaurant_detail.currency.prefix }}</small>
                  +{{ option.price }}
                  <small>{{ restaurant_detail.currency.suffix }}</small
                  >)
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column"></div>
        <div class="column" @click="addCustomizedFoodItem">
          <button class="button is-danger">Add to Cart</button>
        </div>
        <div class="column"></div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { SweetModal, SweetModalTab } from "sweet-modal-vue";

export default {
  name: "listViewParent",
  computed: mapState(["menu", "restaurant_detail"]),
  components: {
    SweetModal,
    SweetModalTab,
  },
  data() {
    return {
      section_id: 0,
      sub_section_id: 0,
      item_id: 0,
      food_item: {
        id: 0,
        name: "",
        price: 0,
        description: "",
        image: "",
        customize: 0,
        restaurant_id: 0,
        food_type: "",
        menu_section_id: 0,
        menu_sub_section_id: 0,
        food_attributes: [],
        calculated_price: 0,
        variations: [
          {
            id: 0,
            max: 0,
            min: 0,
            options: [
              {
                enabled: true,
                checked: false,
                id: 0,
                name: "",
                price: 0,
              },
            ],
            title: "",
            type: "",
          },
        ],
        //  cart
      },
      selectedFoodItem: {
        calculated_price: 0,
        customize: false,
        description: "",
        id: 0,
        image: "",
        name: "",
        price: 0,
        variations: [
          {
            id: 0,
            max: 0,
            min: 0,
            options: [
              {
                enabled: true,
                checked: false,
                id: 0,
                name: "",
                price: 0,
              },
            ],
            title: "",
            type: "",
          },
        ],
      },
    };
  },
  methods: {
    checkBoxType(var_index) {
      let max = parseInt(this.selectedFoodItem.variations[var_index].max);
      if (!max) return true;

      return max > 1;
    },
    generateUid() {
      this.selectedFoodItem.calculated_price = this.selectedFoodItem.price;
      this.uid = this.selectedFoodItem.id + ",";

      for (let variation of this.selectedFoodItem.variations) {
        for (let option of variation.options) {
          if (option.checked) {
            this.uid += String(option.id);
            this.selectedFoodItem.calculated_price += parseInt(option.price);
          }
        }
      }
    },

    optionSelected(var_index, opt_index, e, radio = false) {
      if (radio) {
        for (
          let i = 0;
          i < this.selectedFoodItem.variations[var_index].options.length;
          i++
        ) {
          this.selectedFoodItem.variations[var_index].options[
            i
          ].checked = false;
        }
      }

      this.selectedFoodItem.variations[var_index].options[opt_index].checked =
        e.target.checked;

      this.generateUid();

      if (!radio) this.validateMax(var_index, opt_index);
    },
    validateMax(var_index, opt_index) {
      let min = this.selectedFoodItem.variations[var_index].min;
      let max = this.selectedFoodItem.variations[var_index].max;

      let checked_opt = this.selectedFoodItem.variations[
        var_index
      ].options.filter((opt) => {
        document.getElementById(`opt${opt.id}`).disabled = false;
        return opt.checked === true;
      }).length;
      let disable_remaining = checked_opt >= max;

      for (
        let i = 0;
        i < this.selectedFoodItem.variations[var_index].options.length;
        i++
      ) {
        if (disable_remaining)
          document.getElementById(
            `opt${this.selectedFoodItem.variations[var_index].options[i].id}`
          ).disabled = !this.selectedFoodItem.variations[var_index].options[i]
            .checked;
      }
    },

    mapSelectedFoodItem(food_item) {
      let flag = true;
      this.selectedFoodItem.calculated_price = food_item.price;
      this.selectedFoodItem.customize = food_item.customize;
      this.selectedFoodItem.description = food_item.description;
      this.selectedFoodItem.id = food_item.id;
      this.selectedFoodItem.image = food_item.image;
      this.selectedFoodItem.price = food_item.price;
      this.selectedFoodItem.name = food_item.name;
      for (let variation of food_item.variations) {
        let temp_var = {
          id: variation.id,
          max: variation.max,
          min: variation.min,
          options: [],
          title: variation.title,
          type: variation.type,
        };

        for (let option of variation.options) {
          temp_var.options.push({
            id: option.id,
            name: option.name,
            price: option.price,
            enabled: true,
            checked: false,
          });
        }
        if (flag) this.selectedFoodItem.variations[0] = temp_var;
        else this.selectedFoodItem.variations.push(temp_var);
        flag = false;
      }
    },
    addCustomizedFoodItem() {
      let item = {
        id: this.selectedFoodItem.id,
        uuid: this.uid,
        name: this.selectedFoodItem.name,
        min_item: this.selectedFoodItem.min_item,
        price: this.selectedFoodItem.calculated_price,
        quantity: 1,
        restaurant_id: this.food_item.restaurant_id,
        variations: this.selectedFoodItem.variations,
      };

      this.$store.commit("add_to_cart", {
        item: item,
      });
      this.$refs.variationModal.close();

      this.selectedFoodItem = {
        calculated_price: 0,
        customize: false,
        description: "",
        id: 0,
        image: "",
        name: "",
        price: 0,
        variations: [
          {
            id: 0,
            max: 0,
            min: 0,
            options: [
              {
                enabled: true,
                checked: false,
                id: 0,
                name: "",
                price: 0,
              },
            ],
            title: "",
            type: "",
          },
        ],
      };

      this.calculated_price = 0;
      this.uid = "";
      this.$vToastify.success({
        position: "top-right",

        title: "Item Added",

        body: "Your food item is added to cart",

        type: "success",

        canTimeout: true,

        defaultTitle: true,

        duration: 1500,
      });
    },

    addFoodItem(food_item) {
      console.log(food_item);
      if (food_item.customize) {
        this.selectedFoodItem = {
          calculated_price: 0,
          customize: false,
          description: "",
          id: 0,
          image: "",
          name: "",
          price: 0,
          variations: [
            {
              id: 0,
              max: 0,
              min: 0,
              options: [
                {
                  enabled: true,
                  checked: false,
                  id: 0,
                  name: "",
                  price: 0,
                },
              ],
              title: "",
              type: "",
            },
          ],
        };

        this.mapSelectedFoodItem(food_item);

        this.calculated_price = this.selectedFoodItem.price;

        this.$refs.variationModal.open();
      } else {
        let item = {
          id: food_item.id,
          uuid: food_item.id,
          name: food_item.name,
          price: food_item.price,
          min_item: food_item.min_item,
          quantity: 1,
          restaurant_id: food_item.restaurant_id,
        };

        this.$store.commit("add_to_cart", {
          item: item,
        });

        this.$vToastify.success({
          position: "top-right",

          title: "Item Added",

          body: "Your food item is added to cart",

          type: "success",

          canTimeout: true,

          defaultTitle: true,

          duration: 1500,
        });
      }
    },
  },

  mounted() {
    const nested = new HandyCollapse({
      nameSpace: "nested",
      closeOthers: false,
    });
  },
};
</script>

<style lang='scss' scoped>
.list-holder {
  & .section-holder {
    margin-bottom: 10px;
  }

  & .button {
    justify-content: space-between;

    & .price {
      color: #4caf50;
      font-weight: 500;
    }
  }

  & .acc-btn {
    background-color: #f8f5f5;
    color: #101010;
  }

  & .box {
    border-radius: 0;
    border-color: #f8f5f5;

    & .child {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    & .media {
      & .media-content {
        padding: 0 30px;
      }
    }

    & .opt-holder {
      margin-top: 10px;
      border-top: 1px solid #b9b9b9;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;

      .count-number {
        display: flex;

        .count-number-input {
          width: 40px;
          text-align: center;
          border: 1px solid #b9b9b9;
          height: 24px;
          border-radius: 0;
          vertical-align: bottom;
        }
      }
    }
  }

  .heart-clicked {
    background: red;
    border: 1px solid red;
  }

  .heart-btn {
    &:hover {
      background: red;
      border: 1px solid red;
    }

    &:focus {
      background: red;
      border: 1px solid red;
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .list-holder {
    & .box {
      & .media {
        flex-wrap: wrap;

        & .media-content {
          margin: 10px 0;
          padding: 0;
        }

        & .media-right {
          margin: 0 auto;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .list-holder {
    & .section {
      padding: 0;
    }
    & .box {
      padding: 10px;
      & .child {
        & .sub_button {
          white-space: pre-line !important;
          height: auto;
        }
      }

      & .media {
        flex-wrap: wrap;

        & .media-content {
          margin: 10px 0;
          padding: 0;
        }

        & .media-right {
          margin: 0 auto;
        }
      }

      & .opt-name {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>