<template>
  <div class="columns is-centered is-mobile is-marginless">
    <div class="column has-text-centered">
      <!-- <a :href="`tel:${restaurant_detail.phone}`" class="icon-btns">
        <span class="icon">
          <img :src="`${$store.getters.base_url}/img/icons/phone-solid.svg`" />
        </span>
      </a>-->
    </div>
    <div class="column has-text-centered">
      <figure class="image large-logo" v-if="showLogo">
        <img :src="logo" alt />
      </figure>
      <p class="restaurant-description">
        {{ restaurant_detail.description }}
      </p>
    </div>
    <div class="column has-text-centered">
      <a
        href="javascript:void(0);"
        class="icon-btns lang-select"
        @click="langDropDown"
      >
        <span class="icon">
          <img :src="`${$store.getters.base_url}/img/icons/translate.svg`" />
        </span>
      </a>
      <div class="lang-list">
        <ul>
          <li
            @click="setLanguage(language.code)"
            v-for="language in restaurant_detail.languages"
            :key="language.id"
          >
            <a href="#">{{ language.code }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BtnsTypeNav",
  computed: mapState(["restaurant_detail"]),

  methods: {
    setHeaderDetail() {
      this.title = this.restaurant_detail.name;
      this.logo = `${this.$store.getters.base_url}/${this.restaurant_detail.logo}`;
    },
    setLanguage(code) {
      this.$store.commit("locale", code);
    },
    langDropDown() {
      let langDropDown = document.querySelector(".lang-list");
      langDropDown.classList.toggle("show-list");
    },
    setShowLogo(route) {
      console.log(route.name);
      if (this.hidden_logo_routes.includes(route.name)) {
        this.showLogo = false;
        return;
      }
      this.showLogo = true;
    },
  },

  watch: {
    restaurant_detail() {
      this.setHeaderDetail();
    },
    $route(newval) {
      this.setShowLogo(newval);
    },
  },
  data() {
    return {
      title: "",
      logo: "",
      showTitle: true,
      showLogo: true,
      hidden_logo_routes: ["sub_section_food_item", "section_food_item"],
    };
  },
  mounted() {
    this.setHeaderDetail();
    this.showTitle = this.$router.currentRoute.path != "/restaurant/feedback/";
    this.setShowLogo(this.$route);
  },
};
</script>

<style lang='scss' scoped>
$foreground: #bc2f25;
.large-logo {
  width: 220px;
  height: 220px;
}

.icon-btns {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $foreground;
  color: #fff;
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.lang-list {
  display: none;
  background: $foreground;
  width: 50px;
  justify-content: center;
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  padding: 12px 0;

  & ul {
    list-style: none;
    margin: 0;

    & li {
      border-bottom: 1px solid #fff;
      margin-bottom: 10px;

      &:last-child {
        border: none;
        margin-bottom: 0;
      }

      & a {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        transition: 0.5s ease;

        &:hover {
          font-weight: 500;
        }
      }
    }
  }
}

.show-list {
  display: flex;
  animation: slide-down 0.3s ease-out;
}

@media (min-width: 320px) and (max-width: 480px) {
  .large-logo {
    width: 150px;
    height: 150px;
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
</style>