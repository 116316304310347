<template>
  <div class="t4top-nav">
    <a href="https://eazyfnb.com" class="brand-logo">
      <img
        :src="`${$store.getters.base_url}/assets/images/subdomain_theme/four/logo.png`"
        width="40"
        alt="logo"
      />
    </a>
    <div class="langs">
      <a
        href="javascript:void(0);"
        class="icon-btns lang-select"
        @click="langDropDown"
      >
        <img
          :src="`${$store.getters.base_url}/assets/images/subdomain_theme/four/language.png`"
        />
      </a>
      <div class="lang-list">
        <ul>
          <li
            v-for="language of restaurant_detail.languages"
            @click="setLanguage(language.code)"
            :key="language.id"
          >
            <a href="#">{{ language.code }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "T4topNav",
  computed: mapState(["restaurant_detail"]),
  data() {
    return {
      languages: [
        {
          id: 1,
          code: "en",
          name: "English",
        },
      ],
    };
  },
  methods: {
    langDropDown() {
      let langDropDown = document.querySelector(".lang-list");
      langDropDown.classList.toggle("show-list");
    },
    setLanguage(code) {
      this.$store.commit("locale", code);
    },
  },
};
</script>

<style lang='scss' scoped>
$foreground: #bc2f25;
.t4top-nav {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;

  .langs {
    img {
      width: 30px;
    }
    .lang-list {
      display: none;
      z-index: 101;
      background: $foreground;
      width: 50px;
      justify-content: center;
      margin: 0 auto;
      border-radius: 20px;
      color: #fff;
      padding: 12px 0;

      & ul {
        list-style: none;
        margin: 0;

        & li {
          border-bottom: 1px solid #fff;
          margin-bottom: 10px;

          &:last-child {
            border: none;
            margin-bottom: 0;
          }

          & a {
            text-decoration: none;
            color: #fff;
            font-size: 16px;
            transition: 0.5s ease;

            &:hover {
              font-weight: 500;
            }
          }
        }
      }
    }
    .show-list {
      display: flex;
      position: absolute;
      right: 0;
      animation: slide-down 0.3s ease-out;
    }
  }
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
</style>