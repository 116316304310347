<template>
  <div class="footer-holder">
    <div class="container">
      <div class="columns is-centered is-mobile">
        <div class="column has-text-centered">
          <a href="https://eazyfnb.com" target="_blank">www.eazyfnb.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuMainFooterComponent"
};
</script>

<style lang="scss" scoped>
.footer-holder {
  flex-shrink: 0;
  & a {
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
}
</style>
