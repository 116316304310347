<template>
  <div class="recommended-holder">
    <div class="container">
      <div class="columns is-multiline">
        <div
          class="column is-12-mobile is-12-tablet is-12-desktop"
          v-if="items.length"
        >
          <h4>{{ restaurant_detail.localization.menu_recommended }}</h4>
        </div>
        <a
          @click="reloadPage(item.route)"
          class="column is-3 has-text-centered item-holder"
          v-for="item in items"
          :key="item.id"
        >
          <figure class="image is-5by4">
            <v-lazy-image :src="item.image" />
          </figure>
          <span>{{ item.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import VLazyImage from "v-lazy-image";
import { mapState } from "vuex";

export default {
  name: "mainRecommendedComponent",
  components: {
    VLazyImage,
  },
  computed: mapState(["recommended", "restaurant_detail"]),
  watch: {
    recommended(data) {
      console.log(data);
      console.log(data);
      this.items = data;
    },
  },
  methods: {
    reloadPage(rout) {
      console.log(rout);
      console.log("clicked");
      location.href = rout;
      location.reload();
    },
  },

  data() {
    return {
      items: [
        {
          route: "",
          image: "",
          name: "",
        },
      ],
    };
  },
  mounted() {
    this.items = this.recommended;
    console.log(this.items);
  },
};
</script>
<style lang="scss" scoped>
.recommended-holder {
  padding: 1rem 3rem 5rem 3rem;

  h4 {
    color: #fff;
    font-size: 35px;
    font-weight: 500;
    text-align: center;
  }

  & .item-holder {
    border: 1px solid #fff;
    position: relative;

    & figure {
      margin-bottom: 12%;
    }

    & span {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 18px;
      font-weight: 500;
      color: white;
      width: 100%;
      text-align: center;
      background-color: rgba(20, 6, 2, 0.6);
      padding: 3px 0;
      text-transform: uppercase;
    }
  }
}
</style>