<template>
  <div class="elevated-wrapper">
    <div class="dark">
      <div class="elevated-div">
        <!-- leave it epmty -->
        <div></div>
        <!-- / -->
        <div class="logo-div">
          <figure class="image is-128x128">
            <img :src="logo" alt />
          </figure>
          <p class="restaurant-description">
            {{ restaurant_detail.description }}
          </p>
        </div>
        <div class="btn-div">
          <div v-if="restaurant_detail.menu_feature">
            <router-link
              to="/menu"
              class="column has-text-centered menu-btn"
              v-if="restaurant_detail.theme_setting.menu_view != 'image'"
            >
              <span>{{ restaurant_detail.localization.menu_home_menu }}</span>
            </router-link>
            <a
              :href="`/menu?lang=${locale}`"
              class="column has-text-centered menu-btn"
              v-if="restaurant_detail.theme_setting.menu_view == 'image'"
            >
              <span>{{ restaurant_detail.localization.menu_home_menu }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="primary">
      <div>
        <router-link
          to="/restaurant/callforwaiter/"
          class="column has-text-centered"
          v-if="restaurant_detail.waiter_feature"
        >
          <figure class="image container is-64x64">
            <img
              :src="`${this.$store.getters.base_url}/assets/images/subdomain_theme/call_waiter.png`"
              alt
            />
          </figure>

          <span>Call Waiter</span>
        </router-link>
      </div>
    </div>
    <div class="light">
      <div>
        <router-link
          to="/restaurant/feedback/"
          class="column has-text-centered"
          v-if="restaurant_detail.feedback_feature"
        >
          <figure class="image container is-64x64">
            <img
              :src="`${this.$store.getters.base_url}/assets/images/subdomain_theme/reviews.png`"
              alt
            />
          </figure>
          <span>Reviews</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MenuThemeThreeHeaderComponent",
  computed: mapState(["restaurant_detail", "locale"]),
  data() {
    return {
      logo: "",
    };
  },
  mounted() {
    this.logo = `${this.$store.getters.base_url}/${this.restaurant_detail.logo}`;
  },
};
</script>

<style lang='scss'>
:root {
  --main-dark: brown;
  --main-primary: red;
  --main-light: pink;
}
.elevated-wrapper {
  width: 100%;
  height: calc(100vh - 100px);
  .dark {
    background-color: var(--main-dark);
    height: 30vh;
    position: relative;
    .elevated-div {
      background-color: #ccc;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: 50vh;
      width: 90%;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & .btn-div {
        margin-bottom: 20px;
        span {
          background: var(--main-primary);
          padding: 10px 20px;
          border-radius: 50px;
          color: white;
        }
      }
    }
  }
  .primary {
    background-color: var(--main-primary);
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    & div {
      margin-top: calc(10% + 100px);
      & a {
        color: white;
      }
    }
  }
  .light {
    background-color: var(--main-light);
    height: 20vh;
    & div {
      & a {
        color: white;
      }
    }
  }
}
</style>